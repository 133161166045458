import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/sla.md"

export default function SlaPage() {
  return (
    <LegalLayout
      description="Read the service level agreement for Ready Five Enterprise accounts."
      heading="Ready Five Enterprise Service Level Agreement (SLA)"
      source={source}
      title="Enterprise SLA"
    />
  )
}
